@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

/** Base **/
body, html, #root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Albert Sans', 'Roboto', sans-serif;
  overflow: hidden;
}

button.MuiButton-sizeLarge {
  line-height: 34px;
}

button.MuiButton-sizeMedium {
  line-height: 30px;
}

button.MuiButton-sizeSmall {
  line-height: 24px;
}

/** TABLE **/
.AppTable {
  display: table;
  width: 100%;
  height: 100%;
}

.AppTableRow {
  display: table-row;
}

.AppTableCell {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

/** Scroll **/
.ScrollSection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

/** Base tags **/
main.AppTableCell {
  height: 100%;
}

footer.AppTableCell {
  padding-top: 80px;
  padding-bottom: 22px;
}

footer.AppTableCell hr {
  padding-top: 50px;
  // @todo
  // border-color: $grayMain;
}

footer.AppTableCell a {
  color: white;
}

footer.AppTableCell .Link {
  color: white;
}

footer.AppTableCell .Copyright {
  // @todo
  // color: $grayMain;
}

footer.AppTableCell .MuiCardHeader-root {
  padding-right: 20px;
}

footer.AppTableCell .MuiCardHeader-content {
  width: calc(100% - 56px)
}

footer.AppTableCell .CardFooter .MuiCardHeader-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

footer.AppTableCell .CardFooter .MuiCardHeader-subheader {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

footer.AppTableCell .Localization button {
  padding-top: 5px;
  font-size: 11px;
  min-width: 30px;
}

@media (max-width: 900px) {
  footer.AppTableCell .CardFooter .MuiCardHeader-title {
    white-space: normal;
  }

  footer.AppTableCell .CardFooter .MuiCardHeader-subheader {
    white-space: normal;
  }
}

@media (max-width: 600px) {
  footer.AppTableCell {
    padding-top: 50px;
  }
  footer.AppTableCell hr {
    padding-top: 24px;
  }
  .Home-Page footer.AppTableCell hr {
    padding-top: 24px;
  }
}

/** Menu **/
.TopBarMenuElement {
  height: 100%;
  color: white;
  z-index: 1;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.TopBarMenuElement .MenuCollapseMD {
  position: absolute;
  top: 87px;
  left: 0;
  right: 0;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.TopBarMenuElement .Logo {
  color: white;
  text-decoration: none;
  opacity: 0.8;
  transition-duration: 200ms;
  transition-property: opacity;
  position: relative;
  top: 2px
}

.TopBarMenuElement .Logo:hover {
  color: white;
  opacity: 1;
}

.TopBarMenuElement .MuiContainer-root {
  padding-top: 25px;
  padding-bottom: 25px;
}

.TopBarMenuElement .MenuButtons button {
  color: white;
  background-color: transparent;
  opacity: 0.8;
  transition-duration: 200ms;
  transition-property: opacity;
  line-height: 24px;
}

.TopBarMenuElement .MenuButtons button.MuiButton-outlined {
  border: 1px solid white;
}

.TopBarMenuElement .MenuButtons button:hover {
  opacity: 1;
}

.MuiPaper-root .MenuPopper {
  padding: 10px 16px;
  margin-top: 5px;
}

.MuiPaper-root .MenuPopper button .MuiButton-startIcon {
  margin-right: 16px
}

.MuiPaper-root .MenuPopper button {
  padding: 5px 16px;
}

.MuiPaper-root .MenuPopper button .MuiBox-root {
  width: 70px;
}

@media (min-width: 650px) {
  .TopBarMenuElement .MenuButtonMD {
    display: none;
  }
}

@media (max-width: 650px) {
  .TopBarMenuElement .LogoBlock {
    display: block;
  }
  .TopBarMenuElement .LogoBlock button {
    color: white;
    border: 1px solid white;
    padding: 5px 8px;
    min-width: 46px;
    opacity: 0.8;
    transition-duration: 200ms;
    transition-property: opacity;
  }
  .TopBarMenuElement .MenuButtonsXL {
    display: none;
  }
}