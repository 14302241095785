.Article-Page .AppTableCell {
  vertical-align: top;
}

.Article-Page .ArticleTitleHeader {
  padding: 60px 0;
  text-align: center;
}

.Article-Page .ArticleContent {
  margin: 40px 0;
}