.Video-Page .VideoContent {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}

.Video-Page .BoxYouTubeMove {
  position: relative;
  z-index: 1;
}

.Video-Page .BoxYouTubeMove.isSticky {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
}

.Video-Page .BoxYouTubeFrame .Control {
  position: relative;
  z-index: 1;
  margin-top: -2px;
}

.Video-Page .BoxYouTubeFrame {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Video-Page .BoxYouTubeFrame .IFrame {
  height: 479px;
  width: 100%;
}

.Video-Page .BoxYouTubeFrame .ContainerButton {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.Video-Page .BoxYouTubeFrame .ContainerButton .ShadowPreview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #3e486217 0%, #283044de 100%);
}

.Video-Page .BoxYouTubeFrame .ContainerButton .Loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.Video-Page .BoxYouTubeFrame .ContainerButton .MuiCircularProgress-root {
  width: 70px !important;
  height: 70px !important;
}

.Video-Page .BoxYouTubeFrame .ContainerButton .MuiSvgIcon-root {
  width: 90px;
  height: 90px;
}

.Video-Page .BoxYouTubeFrame .ContainerButton .AppTableCell .ContainerButtonItems {
  position: relative;
  z-index: 1;
}

.Video-Page .VideoContent .VideoText {
  line-height: 34px;
}

.Video-Page .VideoContent .VideoText p {
  display: inline;
  padding: 4px 8px;
  margin-right: 6px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  transition-duration: 300ms;
}

.Video-Page .VideoContent .VideoText p.Pause {
  opacity: 0.6;
}

.Video-Page .VideoContent .VideoText p:after {
  content: "";
  border-radius: 8px;
  position: absolute;
  display: inline;
  width: 6px;
  height: 6px;
  top: 10px;
  left: -3px;
  background: #51c0ff;
  transition-duration: 200ms;
  opacity: 0;
}

.Video-Page .VideoContent .VideoText p.Next:after {
  opacity: 1;
}

@media (max-width: 800px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 413px;
  }
}

@media (max-width: 700px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 358px;
  }
}

@media (max-width: 600px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 302px;
  }
}

@media (max-width: 500px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 255px;
  }
}

@media (max-width: 450px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 226px;
  }
}

@media (max-width: 400px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 199px;
  }
}

@media (max-width: 350px) {
  .Video-Page .BoxYouTubeFrame .IFrame {
    height: 170px;
  }
  .Video-Page .VideoContent .VideoText {
    line-height: 33px;
  }
  .Video-Page .VideoContent .VideoText p {
    font-size: 14px;
  }
  .Video-Page .VideoContent .VideoText p:after {
    top: 9px;
  }
}