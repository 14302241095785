/*************************/
/** ITEM Main ************/
/*************************/

.ItemMainContainer {
  width: 100%;
  padding: 140px 0;
  position: relative;
  overflow: hidden;
}

.ItemMainContainer .MuiContainer-root {
  position: relative;
}

.ItemMainContainer .TitleMain {
  white-space: pre;
}

.ItemMainContainer .SubtitleMain {
  position: relative;
}

.ItemMainContainer .SubtitleMain:after {
  content: "";
  position: absolute;
  right: 0;
  top: 34px;
  height: 3px;
  border-radius: 2px;
}

.ItemMainContainer:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
   background: linear-gradient(180deg, #3e4862 0%, #edacac 100%);
}

.ItemMainContainer .Content {
  position: relative;
  text-align: right;
}

@media (max-width: 2030px) {
  .ItemMainContainer .MuiContainer-root {
    max-width: 1300px;
  }
}

@media (max-width: 1750px) {
  .ItemMainContainer .MuiContainer-root {
    max-width: 1100px;
  }
}

@media (max-width: 1550px) {
  .ItemMainContainer .MuiContainer-root {
    max-width: 900px;
  }
  .ItemMainContainer {
    padding: 100px 0;
  }
  .ItemMainContainer .MuiContainer-root .Content {
    display: block;
    text-align: left;
  }
  .ItemMainContainer .MuiContainer-root .Content img {
    margin-top: 60px;
  }
  .ItemMainContainer .SubtitleMain:after {
    left: 0;
    right: unset;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle {
    right: auto;
    left: 300px;
    top: -40px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item2 {
    right: auto;
    left: 440px;
    top: 240px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item3 {
    right: auto;
    left: 567px;
    top: 100px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item4 {
    right: auto;
    left: 540px;
    top: 500px;
  }
}

@media (max-width: 1330px) {
  .Wave {
    display: none;
  }
  .ItemMainContainer:before {
    display: none;
  }
}

@media (max-width: 700px) {
  .ItemMainContainer .Content .SubtitleMain {
    font-size: 15px;
  }
  .ItemMainContainer .Content .SubtitleMain:after {
    top: 25px;
    height: 1px;
  }
  .ItemMainContainer .Content img {
    width: 100%;
    margin: 0;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle {
    left: 204px;
    top: -60px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item2 {
    left: auto;
    top: 167px;
    right: 83px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item3 {
    left: 0;
    top: 241px;
  }
  .ItemMainContainer .MuiContainer-root .Content .Circle.item4 {
    right: 32px;
    left: auto;
    top: auto;
    bottom: 10px;
  }
}

/** Wave **/
.Wave {
  position: absolute;
  right: -20px;
  top: -730px;
  height: 1900px;
  width: 1800px;
  border-radius: 30%;
  -webkit-animation: waveSpin 20s linear infinite;
  -moz-animation: waveSpin 20s linear infinite;
  animation: waveSpin 20s linear infinite;
}

.Wave.item2 {
  top: -710px;
  height: 1900px;
  width: 1800px;
  opacity: 0.5;
}

.Wave.item3 {
  top: -690px;
  height: 1900px;
  width: 1800px;
  opacity: 0.5;
}

@keyframes waveSpin {
  100% {
    transform: rotate(360deg);
  }
}

/** Circle **/
.Circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  top: -76px;
  right: 84px;
  position: absolute;
}

.Circle.item2 {
  width: 40px;
  height: 40px;
  right: 25px;
  top: 183px;
}

.Circle.item3 {
  width: 25px;
  height: 25px;
  right: 250px;
  top: -3px;
}

.Circle.item4 {
  width: 36px;
  height: 36px;
  right: 551px;
  top: 121px;
}

/*************************/
/** ITEM Blocks **********/
/*************************/
.ItemBlocksContainer {
  padding: 130px 0;
  text-align: center;
  background-image: url('../../images/home/bg_home.png');
  background-repeat: repeat-x;
  background-position: center;
  color: white !important;
  position: relative;
}

.ItemBlocksContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ItemBlocksContainer .MuiContainer-root {
  position: relative;
  z-index: 1;
}

.ItemBlocksContainer .InfoBlock {
  text-align: left;
  padding: 40px;
  color: white;
}

.ItemBlocksContainer .InfoBlock .BoxWithButton {
  position: relative;
}

.ItemBlocksContainer .InfoBlock .BoxWithButton span.MuiTypography-root {
  visibility: visible;
  transition-duration: 400ms;
  transition-property: opacity;
}

.ItemBlocksContainer .InfoBlock .BoxWithButton button {
  width: 38px;
  height: 38px;
  opacity: 0;
  position: absolute;
  top: 4px;
  left: 0;
  visibility: hidden;
  transition-duration: 400ms;
  transition-property: opacity;
}

.ItemBlocksContainer .InfoBlock h5 {
  white-space: pre;
}

.ItemBlocksContainer .InfoBlock .BoxWithButton button svg {
  font-size: 24px;
}

.ItemBlocksContainer .InfoBlock:hover .BoxWithButton span.MuiTypography-root {
  opacity: 0;
  visibility: hidden;
}

.ItemBlocksContainer .InfoBlock:hover .BoxWithButton button {
  visibility: visible;
  opacity: 1;
}

.ItemBlocksContainer .InfoBlock svg {
  font-size: 50px;
}

@media (max-width: 700px) {
  .ItemBlocksContainer {
    padding: 80px 0;
  }
}

/*************************/
/** ITEM Review **********/
/*************************/
.ItemReviewContainer {
  background-image: url('../../images/home/header-bg-team.jpg');
  position: relative;
  padding: 80px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ItemReviewContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ItemReviewContainer .Content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
}

.ItemReviewContainer .Content .MuiFab-circular {
  background-color: white !important;
  margin: 10px 15px;
  -webkit-filter: inherit !important;
  filter: none !important;
  opacity: 1 !important;
}

.ItemReviewContainer .Content .MuiIconButton-sizeMedium {
  margin-top: 20px;
}

.ItemReviewContainer .MuiGrid-root {
  width: 100%;
}

@media (max-width: 600px) {
  .ItemReviewContainer .MuiGrid-root > div {
    padding: 0;
  }
  .ItemReviewContainer .MuiGrid-root > div:first-child {
    display: none;
  }
  .ItemReviewContainer .MuiGrid-root > div:last-child {
    display: none;
  }
}

/*************************/
/** ITEM AboutMe *********/
/*************************/
.ItemAboutMeContainer {
  padding: 140px 0;
  text-align: right;
}

.ItemAboutMeContainer .title {
  font-size: 80px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -1px;
  text-transform: lowercase;
  position: relative;
}

.ItemAboutMeContainer .title:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 30%;
  top: 58px;
  right: -19px;
  position: absolute;
  animation: spin2 5s linear infinite;
}

@keyframes spin2 {
  100% {
    transform: rotate(360deg);
  }
}

.ItemAboutMeContainer .subtitle {
  font-weight: normal;
}

@media (max-width: 1000px) {
  .ItemAboutMeContainer {
    padding: 90px 0;
  }
  .ItemAboutMeContainer .title {
    width: calc(100% - 18px);
  }
}

@media (max-width: 600px) {
  .ItemAboutMeContainer {
    padding: 60px 0;
  }
  .ItemAboutMeContainer h1.title {
    font-size: 60px;
  }
  .ItemAboutMeContainer h1.title:after {
    top: 42px;
  }
  .ItemAboutMeContainer .title:before {
    top: 41px;
  }
}

@media (max-width: 400px) {
  .ItemAboutMeContainer img {
    display: none;
  }
  .ItemAboutMeContainer .SignatureBlock {
    justify-content: end;
  }
}

/*************************/
/** ITEM Proud ***********/
/*************************/
.ItemProudContainer {
  padding: 120px 0;
  text-align: right;
}

.ItemProudContainer .title {
  font-size: 80px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -1px;
  text-transform: lowercase;
  position: relative;
}

.ItemProudContainer .title:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 30%;
  top: 58px;
  right: -19px;
  position: absolute;
  animation: spin2 5s linear infinite;
}

.ItemProudContainer .subtitle {
  font-weight: normal;
}

.ItemProudContainer .MuiGrid-item .MuiSvgIcon-root {
  font-size: 36px;
  stroke-width: 1;
}

@media (max-width: 1000px) {
  .ItemProudContainer {
    padding: 90px 0;
  }
  .ItemProudContainer .title {
    width: calc(100% - 18px);
  }
}

@media (max-width: 600px) {
  .ItemProudContainer {
    padding: 60px 0;
  }
  .ItemProudContainer h1.title {
    font-size: 60px;
  }
  .ItemProudContainer .title:after {
    top: 42px;
  }
}

@media (max-width: 470px) {
  .ItemProudContainer .title {
    width: 100%;
  }
  .ItemProudContainer .title:after {
    display: none;
  }
}

/*************************/
/** ITEM Start ***********/
/*************************/
.ItemStartContainer {
  padding: 120px 0 120px;
  text-align: center;
}

.ItemStartContainer .MuiContainer-root {
  position: relative;
}

.ItemStartContainer .MuiGrid-root {
  text-align: left;
}

.ItemStartContainer .StartBlock h2 {
  text-transform: none;
  white-space: pre;
}

.ItemStartContainer .StartBlock {
  display: none;
  text-align: left;
  position: absolute;
  bottom: -260px;
  left: 24px;
  right: 24px;
  overflow: hidden;
  color: white;
  padding: 40px;
}

.ItemStartContainer .StartBlock .Content {
  position: relative;
  z-index: 1;
}

.ItemStartContainer .StartBlock:after {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  top: -300px;
  right: -130px;
  border-radius: 50%;
}

@media (max-width: 1000px) {
  .ItemStartContainer {
    text-align: center;
  }
  .ItemStartContainer .StartBlock {
    bottom: -194px;
  }
}

@media (max-width: 700px) {
  .ItemStartContainer {
    padding: 60px 0 60px;
    text-align: center;
  }
  .ItemStartContainer .StartBlock {
    bottom: -174px;
  }
}

@media (max-width: 500px) {
  .ItemStartContainer {
    padding: 60px 0 60px;
    text-align: center;
  }
  .ItemStartContainer .StartBlock {
    padding: 20px;
    bottom: -135px;
  }
}

@media (max-width: 450px) {
  .ItemStartContainer {
    padding: 60px 0 60px;
  }
  .ItemStartContainer .StartBlock .Content {
    display: block;
  }
  .ItemStartContainer .StartBlock {
    bottom: -186px;
  }
  .ItemStartContainer .StartBlock .Content button {
    margin: 20px 0 0;
    float: right;
  }
}