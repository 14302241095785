/*******************************/
/** ITEM MySkills **************/
/*******************************/
.About-Page .MuiMasonry-root {
  width: calc(100% + 24px);
  margin-left: -12px;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader {
  padding: 60px 0 60px 0;
  text-align: center;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .Title h2 {
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .Lottie {
  width: 300px;
  margin: -15px auto 0 auto;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .Title h6 {
  white-space: break-spaces;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .MuiGrid-container {
  margin-top: 30px;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .PaperItem {
  padding: 30px;
  text-align: left;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .PaperItem .MuiTypography-body1 {
  padding-top: 20px;
}

.About-Page .AboutMySkillsContent .AboutMySkillsTitleHeader .PaperItem .MuiTypography-subtitle2 {
  font-size: 16px;
}

/*******************************/
/** ITEM Main ******************/
/*******************************/

.AboutMainElementContainer {
  width: 100%;
  padding: 100px 0 100px 0;
  position: relative;
  overflow: hidden;
}

.AboutMainElementContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.AboutMainElementContainer .MuiContainer-root {
  position: relative;
  z-index: 1;
}

.AboutMainElementContainer .MuiTypography-body1 {
  font-size: 18px;
}

@media (max-width: 1000px) {
  .AboutMainElementContainer {
    padding: 60px 0 60px 0;
  }
}

@media (max-width: 880px) {
  .AboutMainElementContainer .MuiAvatar-root {
    width: 190px;
    height: 190px;
  }
}

@media (max-width: 700px) {
  .AboutMainElementContainer .MuiAvatar-root {
    width: 150px;
  }
}

@media (max-width: 600px) {
  .AboutMainElementContainer .MuiAvatar-root {
    width: 190px;
    height: 190px;
  }
}

/*******************************/
/** ITEM Experience ************/
/*******************************/

.AboutExperienceElementContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 120px 0 30px 0;
  background-image: url('../../images/about/pexels-photo-4050347.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
}

.AboutExperienceElementContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.AboutExperienceElementContainer .TitleBlock {
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.AboutExperienceElementContainer .ContentBlock {
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.AboutExperienceElementContainer .ContentBlock .MuiAvatar-root {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 5px -1px, rgba(255, 255, 255, 0.14) 0px 5px 8px 0px, rgba(255, 255, 255, 0.12) 0px 1px 14px 0px;
}

.AboutExperienceElementContainer .ContentBlock .ContentName {
  font-size: 14px;
  padding-right: 16px;
  white-space: pre;
}

.AboutExperienceElementContainer .ContentBlock .LineTime {
  margin-left: 80px;
  margin-top: 21px;
  padding-right: 20px;
  position: relative;
}

.AboutExperienceElementContainer .ContentBlock .LineTime:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: -25px;
  top: 4px;
  border-radius: 50%;
  background: #51c0ff;
}

.AboutExperienceElementContainer .ContentBlock .MuiGrid-container:first-child .LineTime:before {
  background: #03af72;
}

.AboutExperienceElementContainer .ContentBlock .MuiGrid-container:first-child .LineTime:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  left: -30px;
  top: -1px;
  border-radius: 50%;
  border: 1px solid #03af72;
  opacity: 0;

  -webkit-animation: waveLineTime 3s linear infinite;
  -moz-animation: waveLineTime 3s linear infinite;
  animation: waveLineTime 3s linear infinite;
}

@keyframes waveLineTime {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.AboutExperienceElementContainer .ContentBlock .Map-Frame {
  border: none;
  width: 100%;
  border-radius: 4px;
  height: 250px;
}

.AboutExperienceElementContainer .ContentBlock .MuiTypography-body1 {
  white-space: break-spaces;
}

@media (max-width: 700px) {
  .AboutExperienceElementContainer {
    padding: 80px 0 20px 0;
  }
}

@media (max-width: 600px) {
  .AboutExperienceElementContainer {
    padding: 70px 0 0 0;
  }
  .AboutExperienceElementContainer .ContentBlock .MuiDivider-root {
    margin-bottom: -16px;
  }
}

/*******************************/
/** ITEM Questions *************/
/*******************************/

.AboutQuestionsElementContainer {
  width: 100%;
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}

.AboutQuestionsElementContainer .IconBg {
  width: 180px;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .AboutQuestionsElementContainer {
    padding: 80px 0 80px 0;
  }
}

@media (max-width: 600px) {
  .AboutQuestionsElementContainer {
    padding: 70px 0 70px 0;
  }
}
